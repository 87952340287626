import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import Snaptrip from '../../assets/images/logo.png';
import Booking from '../../assets/images/bookingcom.svg';
import Laterooms from '../../assets/images/laterooms-logo.png';
import ArrowIcon from '../../assets/icons/arrow.svg';
import LoadingIcon from '../../assets/icons/loading.svg';

const Section = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  background: var(--white);
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  padding: 1.75rem 0;
  width: 100%;
  display: ${props => props.isLR && 'flex'};
  flex-direction: ${props => props.isLR && 'column'};
  align-items: ${props => props.isLR && 'center'};

  @media only screen and (min-width: 600px) {
    max-width: ${props=> props.isLR ? "40rem" : "35rem"};
  }
`;

const LogoGroup = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

const Copy = styled.div`
  padding: 0 1.25rem;
`;

const GroupText = styled.div`
  font-size: 0.75rem;
  margin-left: auto;
  font-style: italic;
  margin-top: .125rem;
  color: var(--black);
  @media only screen and (min-width: 600px) {
    margin-top: 0;
  }
`;

const Sub = styled.div`
  font-weight: bold;
  margin-top: 1.25rem;
  color: var(--black);
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: .25rem;
  }
  @media only screen and (min-width: 600px) {
    display: block;
    span:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const Vertical = styled.span`
  display: none;
  @media only screen and (min-width: 600px) {
    display: initial;
  }
`;

const Title = styled.h2`
  margin-bottom: 1.25rem;
  font-size: ${props => props.isLR ? '1.125rem' : '1.5rem'};
  font-weight: bold;
  color: var(--black);
  a {
    text-decoration: none;
    color: var(--black);
  }
  @media only screen and (min-width: 600px) {
    font-size: ${props => props.isLR ? '1.25rem' : '1.5rem'};
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
  padding: 0 1.25rem;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
    margin: 4rem 0 6rem;

    a {
      width: 35%;
    }
  }
`;

const LogoImage = styled.img`
  width: 9rem;
  height: 4.375rem;
  object-fit: contain;

  @media only screen and (min-width: 600px) {
    width: auto;
  }
`;

const BookingLogoImage = styled.img`
  width: auto;
  height: 2rem;
  object-fit: contain;
`;

const LateroomsLogoImage = styled.img`
  width: 100%;
  object-fit: contain;
  width: ${props => props.isLR ? "16rem" : "12rem"};

  @media only screen and (min-width: 600px) {
    width: ${props => props.isLR ? "19rem" : "28rem"};
  }
`;

const LoadingImage = styled.img`
  width: 8rem;
  margin-top: 1.25rem;
  @media only screen and (min-width: 600px) {
    margin-top: 1.75rem;
    width: 10rem;
  }
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.25rem 0;

  img {
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
  }

  .img:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  img:nth-child(2) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  img:nth-child(3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  @-webkit-keyframes ellipsis-dot {
    0% {
      filter: invert(74%) sepia(12%) saturate(6%) hue-rotate(331deg) brightness(82%) contrast(80%);
    }

    75% {
      filter: invert(40%) sepia(63%) saturate(1439%) hue-rotate(341deg) brightness(102%) contrast(97%);
    }

    100% {
      filter: invert(74%) sepia(12%) saturate(6%) hue-rotate(331deg) brightness(82%) contrast(80%);
    }
  }

  @keyframes ellipsis-dot {
    0% {
      filter: invert(74%) sepia(12%) saturate(6%) hue-rotate(331deg) brightness(82%) contrast(80%);
    }

    75% {
      filter: invert(40%) sepia(63%) saturate(1439%) hue-rotate(341deg) brightness(102%) contrast(97%);
    }

    100% {
      filter: invert(74%) sepia(12%) saturate(6%) hue-rotate(331deg) brightness(82%) contrast(80%);
    }
  }

  @media only screen and (min-width: 600px) {
    flex-direction: row;
    margin: 1.25rem;
    width: 30%;
  }
`;

const Arrow = styled.img`
  height: 3rem;
  width: 3rem;
  transform: rotate(90deg);

  @media only screen and (min-width: 600px) {
    transform: rotate(0);
  }
`;

const Details = styled.div`
  border-top: 1px solid var(--drkGrey);
  padding: 1.25rem 1.25rem 0;

  b {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

export default function RedirectPage({data}) {
    let finalUrl;

    let logo = data.settings.logo.asset.fixed.src;

    const parameters = {};
    let region = '';
    let checkInDate = '';
    let checkOutDate = '';

    if (typeof location !== `undefined`) {
        finalUrl = location.href.includes('url=') ? location.href.split('url=')[1] : false;
        // keep this line as some browsers will encode the url query params we use 
        if (finalUrl) {
          let urlParts = finalUrl.split('?')[1].split('&');

          for ( const part of urlParts) {
              const singlePart = part.split('=')
              parameters[singlePart[0].replace('search[', '').replace(']', '')] = decodeURIComponent(singlePart[1])
          }

          checkInDate = parameters['check_in_on'] ? new Date(Date.parse(parameters['check_in_on'])): null;

          checkOutDate = checkInDate ? new Date(checkInDate) : null;

          checkInDate = checkInDate ? checkInDate.toLocaleDateString('en-GB') : null;

          let minimumNights = parseInt(parameters['minimum_nights']);

          checkOutDate ? checkOutDate.setDate(checkOutDate.getDate() + minimumNights) : null;

          checkOutDate = checkOutDate ? checkOutDate.toLocaleDateString('en-GB'): null;

          region = parameters['region'] ? parameters['region'].split(',')[0] : null;
        }

    }

    setTimeout(function () {
        if (finalUrl && typeof window !== `undefined`) {
            window.location.href = finalUrl;
        } else if (!finalUrl) {
          window.location.href = window.location.origin;
        }
    }, 3000);

    return (
        <Section>
          {finalUrl && 
            (finalUrl.includes('laterooms') ? (
            <Content isLR>
                  <Title isLR>
                    Taking you to our sister site
                  </Title>
                <LogoGroup href="https://www.laterooms.com/" target="_blank" rel="noreferrer">
                    <LateroomsLogoImage isLR src={Laterooms} alt="Laterooms logo" />
                <GroupText>Part of the Snaptrip family</GroupText>
                </LogoGroup>
                <Sub>
                  <span>Exclusive member discounts</span>
                  <Vertical>|</Vertical>
                  <span>The biggest range of properties</span>
                  <Vertical>|</Vertical>
                  <span>The best price</span>
                </Sub>
              <LoadingImage src={LoadingIcon} alt="loading" />
            </Content>
          )
            : 
            <Content>
                <Copy>
                    <Title>
                        This search is being powered by <br />
                        {finalUrl ? finalUrl.includes("snaptrip") && <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">Snaptrip</a> : null}
                        {finalUrl ? finalUrl.includes("booking") && <a href="https://www.booking.com?aid=2176555" target="_blank" rel="noreferrer">Booking.com</a> : null}
                    </Title>
                    <p>We're now transferring you...</p>
                </Copy>
                <Logos>
                    <a href="https://www.remotecottages.co.uk/" target="_blank" rel="noreferrer">
                        <LogoImage alt="Remote Cottages logo" src={logo}/>
                    </a>
                    <Arrows>
                        <Arrow src={ArrowIcon} alt="Right arrow"/>
                        <Arrow src={ArrowIcon} alt="Right arrow"/>
                        <Arrow src={ArrowIcon} alt="Right arrow"/>
                    </Arrows>
                    {finalUrl ?  finalUrl.includes("snaptrip") &&
                    <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">
                        <LogoImage src={Snaptrip} alt="Snaptrip logo" />
                    </a>
                    : null}
                    {finalUrl ?  (finalUrl.includes("booking") && !finalUrl.includes("aid=1910501")) &&
                    <a href="https://www.booking.com?aid=2176555" target="_blank" rel="noreferrer">
                        <BookingLogoImage src={Booking} alt="booking.com logo" />
                    </a>
                    : null}
                </Logos>
                { checkInDate && (
                <Details>
                    <b>Search details</b>
                     <p>
                        {region}
                        &nbsp;{checkInDate}
                        &nbsp;to&nbsp;
                        {checkOutDate}
                    </p>
                </Details>
                ) }
            </Content>)}
        </Section>
    );
}

export const query = graphql`
	query {
		settings: sanitySiteSettings(_id: {eq: "RCsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 400, width: 400) {
					base64
					srcWebp
					srcSetWebp
				}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
		}
		regions: allSanityRCregionPage {
			nodes {
				slug {
					current
				}
				name
        showInNav
			}
		}
		themes: allSanityRCtheme {
			nodes {
			name
			slug {
				current
			}
      showInNav
			}
		}
	}
`
